import { render, staticRenderFns } from "./InsShoppingCart.vue?vue&type=template&id=2e836323&scoped=true&"
import script from "./InsShoppingCart.vue?vue&type=script&lang=ts&"
export * from "./InsShoppingCart.vue?vue&type=script&lang=ts&"
import style0 from "./InsShoppingCart.vue?vue&type=style&index=0&id=2e836323&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e836323",
  null
  
)

export default component.exports